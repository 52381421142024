
export default {
  name: 'Catalogs',
  computed: {
    cards() {
      const { files } = this.$store.state?.page?.content

      const getFileSrcByKey = (key) => {
        return files?.[key]?.src || ''
      }

      return [
        {
          image:
            this.$store.state.page.content.files[
              'networks.lander.produt_catalog'
            ],
          title: this.$i18n.t('networks.catalog1'),
          subtitle: this.$i18n.t('networks.catalog1.date'),
          pdf: getFileSrcByKey('product-catalog'),
        },
        {
          image:
            this.$store.state.page.content.files[
              'networks.lander.use_case_catalog'
            ],
          title: this.$i18n.t('networks.catalog2'),
          subtitle: this.$i18n.t('networks.catalog2.date'),
          pdf: this.$i18n.t('use-case-catalog-url'),
        },
      ]
    },
  },
}
